export default {
  data: () => ({
    payment_categories: [
      { id: "1", name: "bank_transfer" },
      { id: "2", name: "credit_card" },
      { id: "13", name: "mobile_payment" },
      { id: "14", name: "papara" },
      { id: "16", name: "epin_gold" }
    ]
  }),
  methods: {
    chooseCtx(ctx, item) {
      console.log(ctx, item);
      if (ctx === "class") {
        switch (item) {
          case "1":
            return "success--text";
          case "2":
            return "red--text";
          case "10":
            return "orange--text";
          default:
            break;
        }
      } else if (ctx === "text") {
        switch (item) {
          case "1":
            return "status.success";
          case "2":
            return "status.unsuccess";
          case "5":
            return "status.process_continues";
          case "10":
            return "status.waiting_for_approval";
          default:
            break;
        }
      }
    },
    choose_payment_category(id) {
      if (id) {
        const category = this.payment_categories.find(item => item.id === id);
        return category.name;
      }
    }
  }
};
